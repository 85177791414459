.box {
   composes: box from '../DataTable/DataTable.module.scss';
}

.dropdownHeader {
   cursor: pointer;
}

.columnTitles {
   composes: columnTitles from '../DataTable/DataTable.module.scss';
}

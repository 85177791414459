.color {
   width: 10px;
   height: 10px;
   background-color: #ccc;
   border-style: solid;
   border-width: 1px;
}

.container {
   display: grid;
   grid-row-gap: 5px;
   grid-column-gap: 10px;
   justify-items: center;
   align-items: center;

   font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   letter-spacing: 0.5px;
   font-weight: 400;
   font-size: 14px;
}

.one {
   grid-column: 1;
   grid-row: 1;
   vertical-align: center;
}

.two {
   grid-column: 2;
   grid-row: 1;
}

.three {
   grid-column: 3;
   grid-row: 1;
}

.input {
   width: 40px;
}

// .row {
//    display: inline-block;
//    white-space: nowrap;
//    overflow: hidden;
// }


// .row {
//    // display: inline-block;
//    // white-space: nowrap;
//    // overflow: hidden;
//    flex-direction: row;
//    flex-wrap: nowrap;
//    display: flex;
//    justify-content: center;
// }
//
.colorBox {
   width: 10px;
   height: 10px;
   min-height: 10px;
   display: inline-block;
   background-color: #ccc;
   position: absolute;
   border-width: 1px;
   border-style: solid;
   margin-right: 2.5%;
}
//
// .color {
//    width: 36px;
//    height: 14px;
//    borderRadius: 2px;
//    // background: rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a });
// }
//
// .swatch {
//     padding: 5px;
//     background: #fff;
//     borderRadius: 1px;
//     boxShadow: 0 0 0 1px rgba(0,0,0,.1);
//     display: inline-block;
//     cursor: pointer;
// }
//
// .name {
//    margin-right: 5%;
//    margin-left: 5%;
//    display: inline-block;
// }
//
// .input {
//
// }

@font-face {
    font-family: "Nike TG";
    font-style: normal;
    font-weight: normal;
    src: url(//web.nike.com/ncss/0.15/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot);
    src: url(//web.nike.com/ncss/0.15/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix) format('embedded-opentype'), url(//web.nike.com/ncss/0.15/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.woff) format('woff'), url(//web.nike.com/ncss/0.15/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.ttf) format('truetype'), url(//web.nike.com/ncss/0.15/admin/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.svg#257c802f-349c-4b4d-aefa-546d5de15ec6) format('svg')
}

@font-face {
  font-family: TradeGothicBold;
  src: url("../../style/TradeGothicBold.woff") format("woff");
}

@font-face {
    font-family: OneNikeCurrency;
    src: url("../../style/OneNikeCurrency.woff") format('woff');
}

@font-face {
   font-family: OneNikeGlyphs;
   src: url("../../style/nike-glyphs.woff");
}

.box {
   height:24px;
   padding-bottom:4px;
   padding-left:8px;
   padding-right:8px;
   padding-top:4px;
   white-space:nowrap;
}

.ELBa {
   background-color:rgb(247, 247, 247);
   border-bottom-color:rgb(229, 229, 229);
   border-bottom-left-radius:2px;
   border-bottom-right-radius:2px;
   border-bottom-style:solid;
   border-bottom-width:1px;
   border-collapse:collapse;
   border-image-outset:0px;
   border-image-repeat:stretch;
   border-image-slice:100%;
   border-image-source:none;
   border-image-width:1;
   border-left-color:rgb(229, 229, 229);
   border-left-style:solid;
   border-left-width:1px;
   border-right-color:rgb(229, 229, 229);
   border-right-style:solid;
   border-right-width:1px;
   border-top-color:rgb(229, 229, 229);
   border-top-left-radius:2px;
   border-top-right-radius:2px;
   border-top-style:solid;
   border-top-width:1px;
   box-sizing:border-box;
   color:rgb(17, 17, 17);
   cursor:pointer;
   display:block;
   font-family: OneNikeCurrency, TradeGothicBold;
   font-size:13px;
   font-stretch:100%;
   font-style:normal;
   font-variant-caps:normal;
   font-variant-east-asian:normal;
   font-variant-ligatures:normal;
   font-variant-numeric:normal;
   font-weight:400;
   height:30px;
   line-height:28px;
   margin-bottom:0px;
   margin-left:0px;
   margin-right:0px;
   margin-top:0px;
   overflow-x:hidden;
   overflow-y:hidden;
   padding-bottom:0px;
   padding-left:20px;
   padding-right:38px;
   padding-top:0px;
   text-align:left;
   text-decoration-color:rgb(17, 17, 17);
   text-decoration-line:none;
   text-decoration-style:solid;
   text-size-adjust:100%;
   user-select:none;
   vertical-align:middle;
   white-space:nowrap;
   -webkit-border-horizontal-spacing:0px;
   -webkit-border-vertical-spacing:0px;
   -webkit-font-smoothing:antialiased;
}

.container {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.filters {

   // * {
   //    vertical-align: middle;
   // }
   // i {
   //    vertical-align: middle;
   // }
   // div {
   //    display: inline-block;
   // }
   // display: inline-block;
   // width: 100%;
}

.border-right-light-grey {
    box-shadow: inset -1px 0 0 0 #e5e5e5
}

.border-bottom-light-grey {
    box-shadow: inset 0 -1px 0 0 #e5e5e5;
}

.border-left-light-grey {
    box-shadow: inset 1px 0 0 0 #e5e5e5
}

.border-top-light-grey {
    box-shadow: inset 0 0 0 1px #e5e5e5
}

.new-class {
   composes: border-right-light-grey
}

.dataTableWrapper {
   line-height: 24px;
}

.dataTable {
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 14px;
   vertical-align: baseline;
   overflow: auto;

   background-color: white;

   .tableHeaderRowBorder {
      box-shadow: rgb(229, 229, 229) 0px -1px 0px 0px inset;
   }

   tbody > tr:nth-child(2n) {
      background-color: #F5F5F5;
   }

}

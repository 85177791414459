.label {
   composes: label from '../DataCheckbox/DataCheckbox.module.scss';
   div {
      padding-right: 5px;
   }
}

.container {
   display: flex;
   flex-direction: row;
   align-items: center;
   flex-wrap: nowrap;
   div {
      display: inline-block;
   }
}

$off-white: rgb(247, 247, 247);

.box {
   height:24px;
   padding-bottom:4px;
   padding-left:8px;
   padding-right:8px;
   padding-top:4px;
   white-space:nowrap;
}

.bottom1 {
   box-shadow: inset 0 -1px 0 0 #e5e5e5;
}

.bottom2 {
   box-shadow: inset 0 -1px 0 0 #e5e5e5;
   background: $off-white; //fixed not showing from moving to tr from th (columnTitles)
}

.thFiller {
   width: 2000px;
}

.tableTitle {
   color: #222;
}

th {
   font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   letter-spacing: 0.5px;
   font-weight: 400;
   // box-shadow: inset -1px 0 0 0 #e5e5e5;
}

table {
   -webkit-border-horizontal-spacing:0px;
   -webkit-border-vertical-spacing:0px;
}

.columnTitles {
   box-shadow: inset -1px 0 0 0 #e5e5e5;
}

table td:last-child {
    width: 100%;
}

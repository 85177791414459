.container {
   display: flex;
   flex-direction: row;
   align-items: center;
   flex-wrap: nowrap;
   div {
      display: inline-block;
   }
}

.label {
   padding: 5px;
   // display: inline-block;
   font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 14px;
   letter-spacing: 0.5px;
   // display: inline;
   // div {
   //    padding-right: 5px;
   // }
   // width: 40%;

   //experimental
   // display: inline-block;
   // position: relative;
   div {
      // display: inline-block;
      padding-right: 5px;
   }
}

// .dataRange {
//    display: inline-block;
// }

.input {
   width: 40px;
}

.to {
   font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 14px;
   letter-spacing: 0.5px;
}

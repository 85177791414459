.label {
   padding: 5px;
   display: inline-block;
   font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 14px;
   letter-spacing: 0.5px;
}

.item {
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
   font-size: 14px !important;
}

.popover {
   position: absolute;
   z-index: 10;
}

.cover {
   position: fixed;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 0px;
}

.sketchPicker { //makes sure color picker popup is over other popups
   // z-index: 10;
   position: relative;
}

.settings {
   // vertical-align: middle;
   // text-align: center;
   // padding: 5px;
   // text-align: right;
   // width: 100%;
   padding-left: 5px;
}
.title {
   font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   letter-spacing: 0.5px;
   font-weight: 400;

   &:hover, &:active {
      background-color: white !important;
   }

   span {
      background-color: transparent !important;
   }
}

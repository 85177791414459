@value highAlertColor: #F8D7DA;
@value mediumAlertColor: #FFE7CD;
@value lowAlertColor: #FFF3CD;

.highAlert {
   background-color: highAlertColor;
}

.mediumAlert {
   background-color: mediumAlertColor;
}

.lowAlert {
   background-color: lowAlertColor;
}

.box {
   height:24px;
   padding-bottom:4px;
   padding-left:8px;
   padding-right:8px;
   padding-top:4px;
   white-space:nowrap;
}

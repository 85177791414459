th * {
   font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   letter-spacing: 0.5px;
   font-weight: 400;
   // box-shadow: inset -1px 0 0 0 #e5e5e5;
}

.settings {
   text-align: right;
   width: 100%;
}

.menuTitle {
  padding: 3px 16px;
  cursor: default;
  position: relative;
  white-space: nowrap;
  text-align: center;
  font-family: "Nike TG";
  font-size: 16px;
}

.fontItem {
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.box {
   // height:24px;
//    padding-bottom:4px;
//    padding-left:8px;
   padding-right:8px;
//    padding-top:4px;
//    white-space:nowrap;
}

/* containers */
// .menu {
//   position: absolute;
//   background-color: white;
//   border-radius: 5px;
//   box-shadow: 0 0 5px 0 gray;
//   padding: 3px 0;
//   font-size: 14px;
//   color: black;
//   z-index: 9;
// }
//
// .menu hr {
//   margin: 10px 0;
//   border-style: ridge;
// }
//
// .submenu {
//   position: absolute;
//   top: -3px;
//   left: 0;
//   margin-left: 100%;
//   background-color: white;
//   border-radius: 5px;
//   box-shadow: 0 0 5px 0 gray;
//   padding: 3px 0;
//   color: black;
// }
//
// .menuInput {
//   padding: 0 10px;
// }

/* components */


// .menuItem:hover:not(.disabled), .menuItem.highlight {
//   background-color: rgb(53,142,254);
//   color: white;
// }
//
// .menuItem.disabled {
//   color: gray;
// }
//
// .menuItem.rightShift {
//   padding-right: 4px;
// }
//
// .menuItem .showOnHover {
//   opacity: 0;
// }
//
// .menuItem:hover .showOnHover {
//   opacity: 1;
// }

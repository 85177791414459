.active {
   color: green;
}

.inactive {
   color: red;
}

.icon {
   padding-right: 5%;
}
